/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */

import { useEffect, useState } from 'react';
// THIRD PARTY PACKAGE
import moment from 'moment';
import { enGB } from 'date-fns/locale';
// STYLE
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
// API AND VARIABLE
import { defaultDateTimeFilterWithoutFormat } from 'src/@core/constants/variables';
// CUSTOM DFUNCTION
import { handleToast } from 'src/utils/toast';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
} from '@mui/material';
import { DateRange } from 'react-date-range';

function DatePickerReact({
  calenderDialog = { value: false, onFalse: () => {} },
  filterData,
  setFilterData,
  getLoader,
  getDataFunctions,
  setSearchCall,
  filterTypes,
  radioBtnValue,
  setRadioBtnValue,
  isDateFilterType = false,
  setDateChange,
  page = 'no',
  setDateChangeInsurance,
  dialogValue,
}) {
  const [filterDate, setFilterDate] = useState({
    startDate: defaultDateTimeFilterWithoutFormat.start_date_time,
    endDate: defaultDateTimeFilterWithoutFormat.end_date_time,
    key: 'selection',
  });

  const handleRadioBtnChange = (event) => {
    setRadioBtnValue(event.target.value);
  };

  const handleSelect = (ranges) => {
    setFilterDate(ranges.selection);
  };

  useEffect(() => {
    setFilterDate({
      startDate: moment(filterData.start_date_time, 'DD-MM-YYYY').toDate(),
      endDate: moment(filterData.end_date_time, 'DD-MM-YYYY').toDate(),
      key: 'selection',
    });
  }, [filterData]);

  return (
    <Dialog
      maxWidth="md"
      open={calenderDialog.value}
      onClose={() => {
        calenderDialog.onFalse();
        setFilterDate({
          startDate: defaultDateTimeFilterWithoutFormat.start_date_time,
          endDate: defaultDateTimeFilterWithoutFormat.end_date_time,
          key: 'selection',
        });
      }}
    >
      <DialogTitle sx={{ pb: 2 }}>Select Date Range</DialogTitle>

      <DialogContent>
        {isDateFilterType && (
          <RadioGroup row value={radioBtnValue} onChange={handleRadioBtnChange}>
            {filterTypes?.map((data) => (
              <FormControlLabel
                key={data?.label}
                value={data?.value}
                control={<Radio size="medium" />}
                label={data?.label}
                sx={{ textTransform: 'capitalize' }}
              />
            ))}
          </RadioGroup>
        )}
        <Stack
          justifyContent="center"
          spacing={3}
          direction={{ xs: 'column', sm: 'row', md: 'row' }}
        >
          <DateRange
            ranges={[filterDate]}
            onChange={handleSelect}
            moveRangeOnFirstSelection={false}
            locale={enGB} // Set the locale
            months={2}
            rangeColors={['#CC843A']}
            direction="horizontal"
            dragSelectionEnabled
          />
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" color="primary" onClick={calenderDialog.onFalse}>
          Cancel
        </Button>

        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            if (getLoader) {
              handleToast(0, 'Please wait for the current operation to finish.');
              return;
            }

            if (!filterDate.startDate || !filterDate.endDate) {
              handleToast(0, 'Please select valid start and end dates.');
              return;
            }

            const filterDateData = {
              ...filterData,
              start_date_time: moment(filterDate.startDate).format('DD-MM-YYYY'),
              end_date_time: moment(filterDate.endDate).format('DD-MM-YYYY'),
            };
            setFilterData(filterDateData);
            setSearchCall(true);
            if (page === 'addloan') {
              setDateChange(true);
            }
            if (page === 'insurance') {
              setDateChangeInsurance(true);
            }
            if (page === 'dashboard') {
              dialogValue.onTrue();
            }
            calenderDialog.onFalse();
            getDataFunctions(filterDateData);
          }}
        >
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DatePickerReact;
