/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
import { Button } from '@mui/base';
import { IconButton, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { defaultDateFilter } from 'src/@core/constants/variables';
import Iconify from 'src/components/iconify';

export default function FilterDateDisplay({
  filterData,
  getLoader,
  getDataFunction,
  searchCall,
  setSearchCall,
  setFilterData,
  setDateChange,
  dateChange,
  page = 'no',
  setDateChangeInsurance,
  DateChangeInsurance,
}) {
  return (
    <div>
      <Stack
        spacing={1}
        sx={{
          mr: 1,
          mt: 1,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'end',
          alignItems: 'center',
        }}
      >
        <Typography variant="button">
          {`From : ${filterData?.start_date_time} To : ${filterData?.end_date_time} `}
        </Typography>

        {page === 'addloan'
          ? dateChange && (
              <IconButton
                size="small"
                onClick={() => {
                  getDataFunction(defaultDateFilter);
                  setSearchCall(false);
                  setFilterData(defaultDateFilter);
                  setDateChange(false);
                }}
              >
                <Iconify icon="tabler:x" color="red" />
              </IconButton>
            )
          : page === 'insurance'
            ? DateChangeInsurance && (
                <IconButton
                  size="small"
                  onClick={() => {
                    getDataFunction(defaultDateFilter);
                    setSearchCall(false);
                    setFilterData(defaultDateFilter);
                    setDateChangeInsurance(false);
                  }}
                >
                  <Iconify icon="tabler:x" color="red" />
                </IconButton>
              )
            : searchCall && (
                <IconButton
                  onClick={() => {
                    getDataFunction(defaultDateFilter);
                    setSearchCall(false);
                    setFilterData(defaultDateFilter);
                    setSearchCall(false); // This condition seems redundant if page !== 'addloan'
                  }}
                >
                  <Iconify icon="tabler:x" color="red" />
                </IconButton>
              )}
      </Stack>
    </div>
  );
}

// export default FilterDateDisplay

FilterDateDisplay.propTypes = {
  filterData: PropTypes.node.isRequired,
};
