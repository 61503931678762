/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */

import { forwardRef, useEffect, useState } from 'react';

// API AND VARIABLES
import { getPayoutTotalApi } from 'src/@core/api/addloan';
import { defaultDateFilter } from 'src/@core/constants/variables';
import { countInsuranceCommissionApi } from 'src/@core/api/insurance';
// HOOKS
import { useBoolean } from 'src/hooks/use-boolean';
// CUSTOM FUNCTIONS
import { handleToast } from 'src/utils/toast';
import { isUserLoggedIn } from 'src/utils/utils';

// CUSTOM COMPONENTS
import Iconify from 'src/components/iconify';
import DatePickerReact from 'src/components/reactdaterange';
import FilterDateDisplay from 'src/components/filterDateDisplay';

// MUI
import {
  Slide,
  Dialog,
  Button,
  Divider,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { useModal } from '.';

export default function DealerDetailsModal({ dialog }) {
  const UserData = isUserLoggedIn();
  const calenderDialog = useBoolean();
  const getDisbussDataLoading = useBoolean();
  const [dateChange, setDateChange] = useState(false);
  const [dealerWisePayout, setDealerWisePayout] = useState('');
  const [dealerWiseCommission, setDealerWiseCommission] = useState('');
  const [filterData, setFilterData] = useState({ ...defaultDateFilter });
  const [dealerWiseApprovedAmount, setDealerWiseApprovedAmount] = useState('');
  const Transition = forwardRef((props, ref) => <Slide direction="down" ref={ref} {...props} />);
  const filterTypes = [
    { value: 1, label: 'Generated Date' },
    { value: 2, label: 'Approved Date' },
  ];
  const [radioBtnValue, setRadioBtnValue] = useState(filterTypes[0].value);
  const { closeModal } = useModal();
  const [searchCall, setSearchCall] = useState(false);

  const handleGetPayoutTotal = async () => {
    await getPayoutTotalApi({
      dealer_id: UserData.user_id,
      filter: {
        start_date_time: filterData.start_date_time,
        end_date_time: filterData.end_date_time,
      },
    })
      .then((response) => {
        setDealerWisePayout(response?.data?.total_payout);
        setDealerWiseApprovedAmount(response?.data?.total_approved_amount);
      })
      .catch((error) => handleToast(0, error?.respone?.data?.description));
  };

  const handleGetTotalCommission = async () => {
    await countInsuranceCommissionApi({
      dealer_id: UserData?.user_id,
      filter: {
        start_date_time: filterData.start_date_time,
        end_date_time: filterData.end_date_time,
      },
    })
      .then((response) => setDealerWiseCommission(response?.data?.total_insurance))
      .catch((error) => handleToast(0, error?.respone?.data?.description));
  };

  const getAllData = () => {
    handleGetPayoutTotal();
    handleGetTotalCommission();
  };

  useEffect(() => {
    getAllData();
  }, [filterData]);

  return (
    <Dialog
      fullWidth
      open={dialog.value}
      onClose={dialog.onFalse}
      maxWidth="xs"
      TransitionComponent={Transition}
    >
      <DialogTitle sx={{ pb: 3 }}>Details</DialogTitle>
      <DialogContent>
        <div
          style={{ fontSize: '13px', display: 'flex', justifyContent: 'end', marginBottom: '10px' }}
        >
          <DatePickerReact
            openDialog={calenderDialog}
            calenderDialog={calenderDialog}
            filterData={filterData}
            setFilterData={setFilterData}
            getDataFunctions={getAllData}
            getLoader={getDisbussDataLoading?.value}
            setSearchCall={setSearchCall}
            filterTypes={filterTypes}
            radioBtnValue={radioBtnValue}
            setRadioBtnValue={setRadioBtnValue}
            // isDateFilterType={true}
            setDateChange={setDateChange}
            dialogValue={dialog}
            page="dashboard"
          />

          <FilterDateDisplay
            filterData={filterData}
            searchCall={searchCall}
            setSearchCall={setSearchCall}
            setFilterData={setFilterData}
            getLoader={getDisbussDataLoading?.value}
            getDataFunction={getAllData}
            setDateChange={setDateChange}
            dateChange={dateChange}
            page="addloan"
          />

          <Button
            variant="outlined"
            sx={{ p: 0, alignSelf: '' }}
            title="Calendar"
            onClick={() => {calenderDialog.onTrue();
              dialog.onFalse();
            }}
          >
            <Iconify icon="lets-icons:date-today-duotone" sx={{ width: 40, height: 35 }} />
          </Button>
        </div>

        <div style={{ fontSize: '13px', display: 'flex', justifyContent: 'space-between' }}>
          <b>Total Disbuss Amount : </b>
          <small>
            <Iconify icon="bx:rupee" sx={{ paddingTop: '10px' }} />
            {dealerWiseApprovedAmount}
          </small>
        </div>
        <Divider variant="middle" sx={{ my: 1 }} />
        <div style={{ fontSize: '13px', display: 'flex', justifyContent: 'space-between' }}>
          <b>Total Payout : </b>
          <small>
            <Iconify icon="bx:rupee" sx={{ paddingTop: '10px' }} />
            {dealerWisePayout}
          </small>
        </div>
        <Divider variant="middle" sx={{ my: 1 }} />
        <div style={{ fontSize: '13px', display: 'flex', justifyContent: 'space-between' }}>
          <b>Total Commission : </b>
          <small>
            <Iconify icon="bx:rupee" sx={{ paddingTop: '10px' }} />
            {dealerWiseCommission}
          </small>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => {
            dialog.onFalse();
            closeModal();
          }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
